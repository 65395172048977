<!-- eslint-disable -->
<template>
  <div class='page_box'>
    <sb-headline title="保险模版"></sb-headline>
    <div class="page_body">
      <el-tabs v-model="categoryCode" type="card" @tab-click="tabsClick">
        <el-tab-pane label="燃油版" name="fuel"></el-tab-pane>
        <el-tab-pane label="新能源版" name="new-energy"></el-tab-pane>
      </el-tabs>
      <div class="page_tab_body" v-loading="pageLoading">
        <el-form ref="form" :model="formData" :rules="formRules" label-width="120px">
          <div class="module_item">
            <div class="module_title">
              <div class="title_line"></div>
              <div>车辆交强险</div>
            </div>
            <div class="form_item" v-for="(item,index) in templateInfo.ctaliInsurances" :key="index">
              <el-form-item :label="'险种'+(index+1)+'：'">
                <el-select class="item_child" v-model="formData.ctaliInsurances[index].insuranceCode" @change="(e)=>selectChange(e,index,'ctaliInsurances')" disabled filterable placeholder="请选择">
                  <el-option
                    v-for="(ele, eleIndex) in insuranceAll.ctaliInsurances"
                    :key="eleIndex"
                    :label="ele.categoryName"
                    :value="ele.categoryCode"
                    :disabled="ele.disabled"
                    placeholder="请选择险种">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="10px">
                <el-input class="item_child" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" @input="inputBlurFn" v-model="formData.ctaliInsurances[index].description" placeholder="请输入险种说明"></el-input>
              </el-form-item>
              <el-form-item label-width="10px">
                <el-input class="item_child" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" @input="inputBlurFn" v-model="formData.ctaliInsurances[index].caseDesc" placeholder="请输入案例说明"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="module_item">
            <div class="module_title">
              <div class="title_line"></div>
              <div>代收车船税</div>
              <el-button type="text" size="mini" @click="attachInsurancesAdd('vehicleVesselTax')">添加代收车船税</el-button>
            </div>
            <div class="form_item" v-for="(item,index) in templateInfo.vehicleVesselTax" :key="index">
              <el-form-item :label="'险种'+(index+1)+'：'">
                <el-select class="item_child" v-model="formData.vehicleVesselTax[index].insuranceCode" @change="(e)=>selectChange(e,index,'vehicleVesselTax')" filterable placeholder="请选择">
                  <el-option
                    v-for="(ele, eleIndex) in insuranceAll.vehicleVesselTax"
                    :key="eleIndex"
                    :label="ele.categoryName"
                    :value="ele.categoryCode"
                    :disabled="ele.disabled"
                    placeholder="请选择险种">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="10px">
                <el-input class="item_child" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" @input="inputBlurFn" v-model="formData.vehicleVesselTax[index].description" placeholder="请输入险种说明"></el-input>
              </el-form-item>
              <el-form-item label-width="10px">
                <el-input class="item_child" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" @input="inputBlurFn" v-model="formData.vehicleVesselTax[index].caseDesc" placeholder="请输入案例说明"></el-input>
              </el-form-item>
              <el-button type="text" size="mini" @click="attachInsurancesDelFn(index, 'vehicleVesselTax')">删除</el-button>
            </div>
          </div>
          <div class="module_item">
            <div class="module_title">
              <div class="title_line"></div>
              <div>车辆商业险</div>
            </div>
            <div class="form_box">
              <div class="form_box_title">
                <div>主险项目</div>
                <el-button type="text" size="mini" @click="attachInsurancesAdd('commercialInsurances')">添加主险险种</el-button>
              </div>
              <div class="form_item" v-for="(item,index) in templateInfo.commercialInsurances" :key="index">
                <el-form-item  :label="'险种'+(index+1)+'：'">
                  <el-select class="item_child" v-model="formData.commercialInsurances[index].insuranceCode" @change="(e)=>selectChange(e,index,'commercialInsurances')" filterable placeholder="请选择主险险种">
                    <el-option
                      v-for="(ele, eleIndex) in insuranceAll.commercialInsurances"
                      :key="eleIndex"
                      :label="ele.categoryName"
                      :value="ele.categoryCode"
                      :disabled="ele.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="10px">
                  <el-input class="item_child" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" @input="inputBlurFn" v-model="formData.commercialInsurances[index].description" placeholder="请输入险种说明"></el-input>
                </el-form-item>
                <el-form-item label-width="10px">
                  <el-input class="item_child" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" @input="inputBlurFn" v-model="formData.commercialInsurances[index].caseDesc" placeholder="请输入案例说明"></el-input>
                </el-form-item>
                <el-button type="text" size="mini" @click="allocationFn(index,'commercialInsurances')">配置保额&选项</el-button>
                <el-button type="text" size="mini" @click="attachInsurancesDelFn(index, 'commercialInsurances')">删除</el-button>
              </div>
            </div>
            <div class="form_box">
              <div class="form_box_title">
                <div>附加险项目</div>
                <el-button type="text" size="mini" @click="attachInsurancesAdd('attachInsurances')">添加附加险种</el-button>
              </div>
              <div class="form_item" v-for="(item,index) in templateInfo.attachInsurances" :key="index">
                <el-form-item  :label="'险种'+(index+1)+'：'">
                  <el-select class="item_child" v-model="formData.attachInsurances[index].insuranceCode" @change="(e)=>selectChange(e,index,'attachInsurances')" filterable placeholder="请选择附加险种">
                    <el-option
                      v-for="(ele, eleIndex) in insuranceAll.attachInsurances"
                      :key="eleIndex"
                      :label="ele.categoryName"
                      :value="ele.categoryCode"
                      :disabled="ele.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="10px">
                  <el-select class="item_child" no-data-text="啊是大事" v-model="formData.attachInsurances[index].primaryInsuranceCode" @change="selectChange" filterable placeholder="请关联主险">
                    <el-option
                      v-for="(ele, eleIndex) in formData.commercialInsurances"
                      :key="eleIndex"
                      :label="ele.insuranceName"
                      :value="ele.insuranceCode"
                      :disabled="ele.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label-width="10px">
                  <el-input class="item_child" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" @input="inputBlurFn" v-model="formData.attachInsurances[index].description" placeholder="请输入险种说明"></el-input>
                </el-form-item>
                <el-form-item label-width="10px">
                  <el-input class="item_child" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" @input="inputBlurFn" v-model="formData.attachInsurances[index].caseDesc" placeholder="请输入案例说明"></el-input>
                </el-form-item>
                <el-button type="text" size="mini" @click="allocationFn(index,'attachInsurances')">配置保额&选项</el-button>
                <el-button type="text" size="mini" @click="attachInsurancesDelFn(index, 'attachInsurances')">删除</el-button>
              </div>
            </div>
          </div>
<!--          <div class="module_item">-->
<!--            <div class="module_title">-->
<!--              <div class="title_line"></div>-->
<!--              <div>其他</div>-->
<!--              <el-button type="text" size="mini" @click="otherInsurancesAdd">添加险种</el-button>-->
<!--            </div>-->
<!--            <div class="form_item" v-for="(item,index) in templateInfo.otherInsurances" :key="index">-->
<!--              <el-form-item :label="'险种'+(index+1)+'：'">-->
<!--                <el-select class="item_child" v-model="formData.otherInsurances[index].insuranceCode" @change="(e)=>selectChange(e,index,'otherInsurances')" filterable placeholder="请选择">-->
<!--                  <el-option-->
<!--                    v-for="(ele, eleIndex) in insuranceAll.otherInsurances"-->
<!--                    :key="eleIndex"-->
<!--                    :label="ele.categoryName"-->
<!--                    :value="ele.categoryCode"-->
<!--                    :disabled="ele.disabled"-->
<!--                    placeholder="请选择险种">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--              <el-form-item label-width="10px">-->
<!--                <el-input class="item_child" v-model="formData.otherInsurances[index].description" placeholder="请输入险种说明"></el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label-width="10px">-->
<!--                <el-input class="item_child" v-model="formData.otherInsurances[index].caseDesc" placeholder="请输入案例说明"></el-input>-->
<!--              </el-form-item>-->
<!--              <el-button type="text" size="mini" @click="allocationFn(index,'otherInsurances')">配置保额&选项</el-button>-->
<!--              <el-button type="text" size="mini" @click="insurancesItemDelFn(index, 'otherInsurances')">删除</el-button>-->
<!--            </div>-->
<!--          </div>-->
        </el-form>
        <div class="form_btn">
          <!-- <el-button @click="cancelFn">取消</el-button> -->
          <el-button type="primary" style="width: 200px;" @click="saveForm">保存</el-button>
        </div>
      </div>
    </div>
    <!-- 配置保额&选项 -->
    <el-dialog title="配置保额&选项" class="template_dialog" :visible.sync="templateVisible" width="60%">
      <el-form :model="formData" ref="formData" :rules="formRules" label-width="130px" v-if="selectRow.type">
        <el-form-item label="险种名称：" prop="">
          <div>{{ formData[selectRow.type][selectRow.index].insuranceName }}</div>
        </el-form-item>
        <el-form-item label="保额数据获取：" prop="">
          <el-radio-group v-model="formData[selectRow.type][selectRow.index].dataSource">
            <el-radio :label="1">客户选择</el-radio>
            <el-radio :label="2">报价单获取</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否必须投保：" prop="">
          <el-radio-group v-model="formData[selectRow.type][selectRow.index].defaultDisplay">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="保额选择类型：" prop="">
          <el-radio-group v-model="formData[selectRow.type][selectRow.index].quotaType">
            <el-radio :label="1">固定选择</el-radio>
            <el-radio :label="2">通过选项选择</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="" v-if="formData[selectRow.type][selectRow.index].quotaType==2">
          <el-button type="text" class="add_option_btn" @click="addOption">添加选项</el-button>
          <div class="option_item" v-for="(item,index) in formData[selectRow.type][selectRow.index].quotaOptionList" :key="index">
            <div>{{ '选项'+(index+1) }}</div>
            <el-input @input="inputBlurFn" v-model="formData[selectRow.type][selectRow.index].quotaOptionList[index].quotaValue" style="width: 100px;"></el-input>
            <el-button type="text" @click="deleteRecommendFn(index)">删除</el-button>
            <el-button v-if="formData[selectRow.type][selectRow.index].quotaOptionList[index].recommend==1" type="text">已推荐</el-button>
            <el-button v-else type="text" @click="recommendFn(index)">设为推荐</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="templateVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        categoryCode: 'fuel',
        pageLoading: false,
        formData: {
          ctaliInsurances: [],                //交强险
          vehicleVesselTax: [],               //代收车船税
          commercialInsurances: [],           //商业险
          attachInsurances: [],               //商业险附加
          vehicleVesselTax:[], // 代收车船税
          otherInsurances:[], //其他
          templateCode: ''
        },
        formRules: {},
        templateInfo: {},                     //模版详情
        insuranceAll: {},                     //所有险种
        templateVisible: false,                //配置保额&选项弹窗
        selectRow: {
          index: '',
          type: ''
        }
      };
    },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 标签点击
    tabsClick(e){
      // 获取模版信息
      this.getTemplateInfo();
    },

    // 车船税添加
    vehicleVesselTaxAdd(){
      this.formData.vehicleVesselTax.push({});
    },

    // 附加险项目添加
    attachInsurancesAdd(key){
      this.formData[key].push({});
      console.log(this.formData)
    },

    // // 其他项目添加
    // otherInsurancesAdd(){
    //   this.formData.otherInsurances.push({});
    //   console.log(this.formData)
    // },
    // 输入刷新
    inputBlurFn(){
      this.$forceUpdate()
    },
    // 险种选择
    selectChange(e,index,type){
      let insuranceAll = JSON.parse(JSON.stringify(this.insuranceAll));
      // let formData = JSON.parse(JSON.stringify(this.formData));
      if(type) {
        insuranceAll[type].forEach(item => {
          if(item.categoryCode == e){
            this.formData[type][index].insuranceName = item.categoryName;
            this.formData[type][index].description = item.description;
            this.formData[type][index].caseDesc = item.caseDesc;
          }
        })
        // this.formData = formData;
      };
      if(type=='commercialInsurances'&&this.formData.attachInsurances&&this.formData.attachInsurances.length>0){
        this.formData.attachInsurances.map((item,itemIndex) => {
          let isExist = this.formData.commercialInsurances.some((ele) => {
            console.log(ele)
            return item.primaryInsuranceCode == ele.insuranceCode
          })
          if(!isExist){
            this.formData.attachInsurances[itemIndex].primaryInsuranceCode = '';
          }
        })
        // this.formData = formData;
      }
      this.$forceUpdate()
      // 禁用已被选择险种
      this.filterInsuranceFn()
    },
    // 附加险删除
    attachInsurancesDelFn(index,key){
      this.$confirm('是删除此条?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData[key].splice(index,1);
        // 禁用已被选择险种
        this.filterInsuranceFn()
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        });
      })
    },

    // 配置保额&选项
    allocationFn(index,type){
      if(!this.formData[type][index].insuranceCode){
        this.$message.warning('请选择险种！')
        return
      }
      if(type=='attachInsurances'&&!this.formData[type][index].primaryInsuranceCode){
        this.$message.warning('请关联主险！')
        return
      }
      this.selectRow.index = index;
      this.selectRow.type = type;
      this.templateVisible = true;
    },
    // 添加选项
    addOption(){
      let selectRow = this.selectRow;
      if(this.formData[selectRow.type][selectRow.index].quotaOptionList&&this.formData[selectRow.type][selectRow.index].quotaOptionList.length>=0){
        this.formData[selectRow.type][selectRow.index].quotaOptionList.push({});
      }else{
        this.formData[selectRow.type][selectRow.index].quotaOptionList = [];
        this.formData[selectRow.type][selectRow.index].quotaOptionList.push({});
      }
      this.$forceUpdate();
    },
    // 配置删除
    deleteRecommendFn(index){
      let selectRow = this.selectRow
      this.formData[selectRow.type][selectRow.index].quotaOptionList.splice(index,1)
      this.$forceUpdate();
    },
    // 设为推荐
    recommendFn(index){
      let selectRow = this.selectRow
      this.formData[selectRow.type][selectRow.index].quotaOptionList.forEach((item,itemIndex) => {
        if(index==itemIndex){
          this.formData[selectRow.type][selectRow.index].quotaOptionList[itemIndex].recommend = 1
        }else{
          this.formData[selectRow.type][selectRow.index].quotaOptionList[itemIndex].recommend = 0
        }
      })
      this.$forceUpdate();
    },
    // 配置保额&选项弹窗确认
    dialogConfirm(){
      this.saveForm();
    },
    // 表单保存
    saveForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch('insurance/postInsuranceTemplateSave',{
            ...this.formData,
          }).then(res => {
            this.$message.success('保存成功！')
            this.templateVisible = false;
            // 获取所有险种
            this.getInsuranceAll();
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 禁用已被选择险种
    filterInsuranceFn(){
      let insuranceAll = this.insuranceAll;
      let formData = this.formData;
      let selectedData = {
        ctaliInsurances: [],        //交强险
        commercialInsurances: [],   //商业险
        attachInsurances: [],       //附加险
        vehicleVesselTax: [],       //车船税
      };
      formData.ctaliInsurances.forEach(item => {
        selectedData.ctaliInsurances.push(item.insuranceCode);
      })
      formData.vehicleVesselTax.forEach(item => {
        selectedData.vehicleVesselTax.push(item.insuranceCode);
      })
      formData.commercialInsurances.forEach(item => {
        selectedData.commercialInsurances.push(item.insuranceCode);
      })
      formData.attachInsurances.forEach(item => {
        selectedData.attachInsurances.push(item.insuranceCode);
      })
      formData.vehicleVesselTax.forEach(item => {
        selectedData.vehicleVesselTax.push(item.insuranceCode);
      })
      formData.otherInsurances.forEach(item => {
        selectedData.otherInsurances.push(item.insuranceCode);
      })
      insuranceAll.ctaliInsurances.map(item => {
        if(selectedData.ctaliInsurances.indexOf(item.categoryCode)!=-1){
          item.disabled = true;
        }else{
          item.disabled = false;
        }
        return item
      })
      insuranceAll.vehicleVesselTax.map(item => {
        if(selectedData.vehicleVesselTax.indexOf(item.categoryCode)!=-1){
          item.disabled = true;
        }else{
          item.disabled = false;
        }
        return item
      })
      insuranceAll.commercialInsurances.map(item => {
        if(selectedData.commercialInsurances.indexOf(item.categoryCode)!=-1){
          item.disabled = true;
        }else{
          item.disabled = false;
        }
        return item
      })
      insuranceAll.attachInsurances.map(item => {
        if(selectedData.attachInsurances.indexOf(item.categoryCode)!=-1){
          item.disabled = true;
        }else{
          item.disabled = false;
        }
        return item
      })
      insuranceAll.vehicleVesselTax.map(item => {
        if(selectedData.vehicleVesselTax.indexOf(item.categoryCode)!=-1){
          item.disabled = true;
        }else{
          item.disabled = false;
        }
        return item
      })
      insuranceAll.otherInsurances.map(item => {
        if(selectedData.otherInsurances.indexOf(item.categoryCode)!=-1){
          item.disabled = true;
        }else{
          item.disabled = false;
        }
        return item
      })
    },

    // 获取模版信息
    getTemplateInfo(){
      this.$store.dispatch('insurance/getInsuranceTemplateInfo',{
        categoryCode: this.categoryCode
      }).then(res => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = res.data[key]
        })
        console.log("表单数据");
        console.log(this.formData);
        this.templateInfo = res.data;
        // 禁用已被选择险种
        this.filterInsuranceFn()
        this.pageLoading = false;
      }).catch(err => {
        this.pageLoading = false;
      })
    },
    // 获取所有险种
    getInsuranceAll(){
      this.pageLoading = true;
      this.$store.dispatch('insurance/getInsuranceAll',{}).then(res => {
        this.insuranceAll = res.data;
        // 获取模版信息
        this.getTemplateInfo();
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 获取所有险种
    this.getInsuranceAll();
  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .page_body{
      padding: 20px 0;
      box-sizing: border-box;
      ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav{
        background: #fff;
        border: none;
      }
      ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item{
        border-bottom: 1px solid #dcdee0;
      }
      ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
        border-bottom: 1px solid #fff;
      }
      ::v-deep .el-tabs__header{
        margin: 0;
      }
      .page_tab_body{
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
        .module_item{
          .module_title{
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .title_line{
              width: 4px;
              height: 16px;
              background: #409EFF;
              margin-right: 5px;
              border-radius: 1px;
            }
          }
          .form_item{
            display: flex;
            // align-items: center;
            .item_child{
              width: 200px;
            }
          }
          .form_box{
            .form_box_title{
              display: flex;
              align-items: center;
            }
          }
        }
        .form_btn{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          padding-top: 20px;
        }
      }
    }
    .template_dialog{
      .add_option_btn{
        margin: 0;
        padding: 0;
      }
      .option_item{
        margin-top: 10px;
        display: flex;
        align-items: center;
        >div:nth-child(1){
          width: 60px;
        }
        >.el-button{
          margin: 0;
          padding: 0;
          margin-left: 20px;
        }
      }
    }
  }
</style>